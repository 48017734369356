export interface Section {
  rootLink?: string
  link: string
  name: string
  index: boolean
}

export interface Social {
  link: string
  icon: string
}

export function isActive(section: Section, pathname: string): boolean {
  const link = section.rootLink != null ? section.rootLink : section.link

  if (section.index) {
    return pathname === link
  }

  return pathname.startsWith(link)
}

export function isInternal(section: Section): boolean {
  return section.link.startsWith("/")
}
